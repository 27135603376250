import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { PackageBundleDetailsProps } from "_interfaces/phoneBooking";
import React, { useState } from "react";
import { formatPriceWithDelimiter } from "functions/helper";

interface Props {
  data?: PackageBundleDetailsProps;
  handleClick?: () => void;
  buttonText?: string;
}

const PlanCardFour: React.FC<Props> = ({ data, handleClick, buttonText }) => {
  const { palette } = useTheme();

  const [hovered, setHovered] = useState<boolean>(false);

  const renderVoicePackageValues = (data: PackageBundleDetailsProps) => {
    const { allNetMinutes, onNetMinutes, offNetMinutes } = data;

    // const allNet =
    //   typeof allNetMinutes === "number"
    //     ? allNetMinutes
    //     : parseFloat(allNetMinutes) || 0;
    const onNet =
      typeof onNetMinutes === "number"
        ? onNetMinutes
        : parseFloat(onNetMinutes) || 0;
    const offNet =
      typeof offNetMinutes === "number"
        ? offNetMinutes
        : parseFloat(offNetMinutes) || 0;

    const availableMinutes: any[] = [
      // allNet > 0 ? { type: "All Net Voice", value: allNet } : null,
      onNet > 0 ? { type: "On Net Voice", value: onNet } : null,
      offNet > 0 ? { type: "All Net Voice", value: offNet } : null,
    ].filter(Boolean);

    if (availableMinutes.length === 0) {
      return <RenderPackageValue type="Voice" value="0" />;
    }

    // if (availableMinutes.length === 1) {
    //   return (
    //     <RenderPackageValue type="Voice" value={`${availableMinutes[0].value}`} />
    //   );
    // }

    return availableMinutes.map((sms: any, index) => (
      <RenderPackageValue key={index} type={sms.type} value={`${sms.value}`} />
    ));
  };

  const renderSMSPackageValues = (data: PackageBundleDetailsProps) => {
    const { allNetSMS, onNetSMS, offNetSMS } = data;

    const allNet =
      typeof allNetSMS === "number" ? allNetSMS : parseFloat(allNetSMS) || 0;
    const onNet =
      typeof onNetSMS === "number" ? onNetSMS : parseFloat(onNetSMS) || 0;
    const offNet =
      typeof offNetSMS === "number" ? offNetSMS : parseFloat(offNetSMS) || 0;

    const availableSMS: any[] = [
      allNet > 0 ? { type: "All Net SMS", value: allNet } : null,
      onNet > 0 ? { type: "On Net SMS", value: onNet } : null,
      offNet > 0 ? { type: "Off Net SMS", value: offNet } : null,
    ].filter(Boolean);

    if (availableSMS.length === 0) {
      return <RenderPackageValue type="SMS" value="0" />;
    }

    // if (availableSMS.length === 1) {
    //   return (
    //     <RenderPackageValue type="SMS" value={`${availableSMS[0].value}`} />
    //   );
    // }

    return availableSMS.map((sms: any, index) => (
      <RenderPackageValue key={index} type={sms.type} value={`${sms.value}`} />
    ));
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          transition: "all .4s ease",
          border: "1px solid",
          borderColor: hovered ? palette.primary.main : "#E5E4E4",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "22px",
          boxShadow: hovered ? "-4px 8px 8px 0px #00000015" : "none",
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        className="fm-poppins"
      >
        <CardContent
          sx={{
            padding: "16px",
          }}
        >
          <Box pb={1}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontSize={{ xs: 18, sm: 24 }}
                fontWeight={800}
                textTransform="capitalize"
                className="fc-primary"
              >
                {data?.isValidityUnlimited
                  ? "Non-Expiry"
                  : data?.validity
                  ? data?.validity === 1
                    ? "Daily"
                    : `${data?.validity} Days`
                  : ""}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Chip
              label="Price"
              sx={{
                minWidth: 80,
                backgroundColor: alpha(palette.primary.main, 0.31),
                color: palette.common.black,
                fontSize: 12,
                fontWeight: 600,
                textTransform: "capitalize",
                overflow: "hidden",
                whiteSpace: "nowrap",
                borderRadius: "4px",
              }}
            />
            <Typography
              pt={1}
              sx={{
                fontSize: { xs: 16, sm: 20 },
                fontWeight: 600,
                color: palette.common.black,
              }}
            >
              {data?.retailPrice ? (
                <>
                  <Box
                    component="span"
                    sx={{
                      color: palette.primary.main,
                    }}
                  >
                    UGX{" "}
                  </Box>
                  {formatPriceWithDelimiter(data.retailPrice)}
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>

          <Box>
            <Box pt={2}>
              <RenderPackageValue
                type="Data"
                value={`${
                  data?.dataVolume?.isUnlimited
                    ? "Non-Expiry"
                    : data?.dataVolume?.quantity && data?.dataVolume?.dataUnit
                    ? `${data?.dataVolume?.quantity} ${data?.dataVolume?.dataUnit}`
                    : "0"
                }`}
              />
              <RenderPackageValue
                type="Daily Limit"
                value={`${
                  data?.dataPerDay?.isUnlimited
                    ? "Non-Expiry"
                    : data?.dataPerDay?.quantity && data?.dataPerDay?.dataUnit
                    ? `${data?.dataPerDay?.quantity} ${data?.dataPerDay?.dataUnit}`
                    : "0"
                }`}
              />

              {data ? renderVoicePackageValues(data) : null}

              <RenderPackageValue
                type="Int. Call Voice"
                value={`${data?.internationalCallMinutes}`}
              />

              {data ? renderSMSPackageValues(data) : null}
            </Box>
          </Box>
        </CardContent>
        {handleClick ? (
          <CardActions
            sx={{ justifyContent: "center", marginTop: "auto" }}
            className="pb-4"
          >
            <Button
              variant="contained"
              className="c-bg-primary-var"
              size="small"
              sx={{
                borderRadius: "6px",
                borderColor: "inherit",
                minWidth: 180,
                fontSize: 14,
              }}
              onClick={handleClick}
            >
              {buttonText || "Buy Now"}
            </Button>
          </CardActions>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

interface RenderPackageValueProps {
  type: string;
  value: string;
}
const RenderPackageValue: React.FC<RenderPackageValueProps> = ({
  type,
  value,
}) => {
  return (
    <Box
      sx={{
        borderBottom: "1px solid",
        borderColor: "#EBEAEA",
        py: 0.8,
        px: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography sx={{ color: "#5E5B5B", fontSize: 14, fontWeight: 500 }}>
            {type}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            sx={{
              color: "#5E5B5B",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanCardFour;
