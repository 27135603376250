import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import BuildOwnBundle from "../../_common/plans/buildOwnBundle";

const BuildBundleLayout = () => {
  const { steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  return (
    <>
      <Box className="text-center" id="build-own-bundle">
        <DesignServicesOutlinedIcon sx={{ fontSize: 40 }} />
        <Box py={2} id="next-step-for-package-bundle-selection">
          <h2 className="heading-1">Build Your Bundle</h2>
          <CustomJoyRide steps={steps} stepIndex={stepIndex} run={true} />
        </Box>
        <Box width={{ xs: "100%", sm: "60%", lg: "40%" }} margin="auto">
          <Typography variant="body2" color="grey.600" textAlign="center">
            Choose an all-in-one subscription that caters to your needs across
            voice, data & SMS.
          </Typography>
        </Box>
      </Box>
      <Box pt={3}>
        <BuildOwnBundle />
      </Box>
    </>
  );
};

export default BuildBundleLayout;
