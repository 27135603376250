import { SearchNumberModel } from "_models/data/data.searchNumber.model";

export const isValidSearchPhoneNumber = (val?: string): boolean => {
  if (!val) return false;
  const reg: RegExp = /^\d{5}$/;
  return reg.test(val);
};

export const validateSearchPhoneNumber = (DATA: SearchNumberModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.searchNumber?.trim()) {
    valid = false;
    errors.push({
      name: "searchNumber",
      error: "Please enter phone number",
    });
  }

  return { valid, errors };
};
