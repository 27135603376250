import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { SxProps, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import PaymentMethod from "component/_common/payment/paymentMethod";
import { paymentOptionsList } from "component/_common/payment/paymentOptionsList";
import { AppStatusCode } from "config/appStatusCode";
import { formatPriceWithDelimiter } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  PhoneBookingCheckPaymentRequest,
  PhoneBookingMakePaymentRequest,
} from "functions/http-requests/phoneBooking";
import RouteConstant from "navigation/constant";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { DispatchBookingDetails } from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { PhoneBookingMakePaymentRequestProps } from "_interfaces/http-requests/phoneBooking";
import {
  FETCH_PAYMENT_TIMEOUT_MINUTES,
  PaymentMethodDataProps,
} from "_interfaces/_common/payment";
import { CheckoutModel } from "_models/data/data.checkout.model";
import { CheckoutErrorModel } from "_models/errors/error.checkout.model";
import ApplyCouponForm from "./applyCouponForm";

const CheckoutLayout = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { palette } = useTheme();

  const { bookingData, bookingStatus, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [state, setState] = useState<CheckoutModel>(new CheckoutModel());
  const [errors, setErrors] = useState<CheckoutErrorModel>(
    new CheckoutErrorModel()
  );

  const [openPaymentOption, setOpenPaymentOption] = useState<boolean>(false);

  const [paymentStatus, setPaymentStatus] = useState<
    "pending" | "success" | "failed" | ""
  >("");

  const isBookingDataAvailable: boolean = useMemo(
    () =>
      Boolean(
        (bookingStatus?.packageBundleDetails ||
          bookingStatus?.buildBundleDetails) &&
          bookingStatus?.phoneDetails &&
          bookingStatus?.accountDetails &&
          bookingStatus?.bookingDetails &&
          bookingStatus?.billDetails
      ),
    [bookingStatus]
  );

  const togglePaymentOption = () => {
    if (!openPaymentOption && !state?.agreeTerms) {
      setErrors((prev) => ({
        ...prev,
        agreeTerms: "You must agree to the terms and conditions.",
      }));

      return;
    }
    setOpenPaymentOption(!openPaymentOption);
  };

  let handleMakePaymentRequest = (DATA: PaymentMethodDataProps) => {
    if (
      DATA?.paymentBy &&
      DATA?.paymentMethod &&
      bookingData?.bookingDetails?._id
    ) {
      let PAYLOAD_DATA: PhoneBookingMakePaymentRequestProps["DATA"] = {
        id: bookingData?.bookingDetails?._id,
        paymentBy: DATA.paymentBy,
        paymentMethod: DATA.paymentMethod,
        momoPhoneNumber: DATA.momoPhoneNumber || undefined,
        airtelMOMOPhone: DATA.airtelMOMOPhone || undefined,
      };

      setState((prev) => ({ ...prev, paymentBy: DATA.paymentBy }));

      Dispatch(toggleLoading(true));
      PhoneBookingMakePaymentRequest({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            setPaymentStatus("pending");
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          Dispatch(toggleLoading(false));
        });
    }
  };

  const handleCheckPaymentRequest = (ID: string, stopPolling: () => void) => {
    PhoneBookingCheckPaymentRequest(ID)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const STATUS = data?.data?.status?.toLowerCase();
          const DATA = data?.data?.booking;
          if (STATUS === "success" && DATA?._id && DATA?.bookingID) {
            setPaymentStatus("success");
            Dispatch(toggleLoading(false));
            navigate(RouteConstant.ORDER_STATUS);

            Dispatch(
              DispatchBookingDetails({
                _id: DATA._id,
                bookingID: DATA.bookingID,
              })
            );
          } else {
            setPaymentStatus("pending");
          }
        } else {
          Dispatch(
            setAlert({
              type: data?.level,
              message: data?.message || "Something went wrong!",
            })
          );
          Dispatch(toggleLoading(false));
          setPaymentStatus("failed");
          stopPolling(); // Stop polling on failure
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        Dispatch(toggleLoading(false));
        setPaymentStatus("failed");
        stopPolling(); // Stop polling on error
      });
  };

  useEffect(() => {
    if (bookingData.bookingDetails?._id && paymentStatus === "pending") {
      let intervalId: NodeJS.Timeout;
      let timeoutId: NodeJS.Timeout;
      let isFinalCall = false;

      const stopPolling = () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };

      const fetchStatus = () => {
        if (isFinalCall) return; // Prevent further calls after final call

        if (bookingData?.bookingDetails?._id) {
          handleCheckPaymentRequest(
            bookingData?.bookingDetails?._id,
            stopPolling
          );
        }
      };

      // Start polling
      fetchStatus(); // Initial call

      intervalId = setInterval(fetchStatus, 4000);

      if (state?.paymentBy === "momo") {
        // Set the timeout to stop polling after the specified limit
        timeoutId = setTimeout(() => {
          stopPolling();
          isFinalCall = true; // Mark that this is the final call
          fetchStatus(); // Ensure one last API call

          // Handle pending status after timeout
          setTimeout(() => {
            if (paymentStatus === "pending") {
              setPaymentStatus("failed");
              Dispatch(
                setAlert({
                  type: "error",
                  message:
                    "Your payment is still pending. Please contact support for assistance.",
                })
              );
              Dispatch(toggleLoading(false));
            }
          }, 4000); // Wait for the final call to resolve
        }, FETCH_PAYMENT_TIMEOUT_MINUTES * 60 * 1000);
      }

      return () => {
        stopPolling(); // Cleanup intervals and timeouts on unmount
      };
    }
  }, [
    bookingData.bookingDetails?._id,
    paymentStatus,
    Dispatch,
    state?.paymentBy,
  ]);

  const chargeDetails: {
    label: string;
    value?: number | string;
    enabled?: boolean;
    cellProps?: SxProps;
    labelProps?: SxProps;
    valueProps?: SxProps;
  }[] = [
    {
      label: "SIM Charges",
      value: bookingData?.billDetails?.subTotalSimCharges,
      enabled: true,
    },
    {
      label: "Package Charges",
      value: bookingData?.billDetails?.subTotalPackageCharges,
      enabled: !!bookingData?.billDetails?.subTotalPackageCharges,
    },
    {
      label: "DATA Charges",
      value: bookingData?.promotionDetails?.promotionId
        ? bookingData?.promotionDetails?.charges?.dataPrice
        : bookingData?.billDetails?.bundleBundleCharges?.dataPrice,
      enabled: true,
    },
    {
      label: "On Net Charges",
      value: bookingData?.promotionDetails?.promotionId
        ? bookingData?.promotionDetails?.charges?.onNetPrice
        : bookingData?.billDetails?.bundleBundleCharges?.onNetPrice,
      enabled: true,
    },
    {
      label: "Off Net Charges",
      value: bookingData?.promotionDetails?.promotionId
        ? bookingData?.promotionDetails?.charges?.offNetPrice
        : bookingData?.billDetails?.bundleBundleCharges?.offNetPrice,
      enabled: true,
    },
    {
      label: "SMS Charges",
      value: bookingData?.promotionDetails?.promotionId
        ? bookingData?.promotionDetails?.charges?.smsPrice
        : bookingData?.billDetails?.bundleBundleCharges?.smsPrice,
      enabled: true,
    },
    {
      label: "Base Charges",
      value: bookingData?.promotionDetails?.charges?.original_totalPrice,
      enabled: !!(
        bookingData?.promotionDetails?.promotionId &&
        bookingData?.bookingDetails?.promotionId &&
        bookingData?.promotionDetails?.charges?.original_totalPrice
      ),
      cellProps: {
        py: 0.2,
        borderBottom: 0,
      },
      labelProps: {
        fontWeight: 500,
        fontSize: 14,
        color: palette.error.main,
      },
      valueProps: {
        textDecoration: "line-through",
        color: palette.error.main,
      },
    },
    {
      label: "Promotion Offer Applied",
      value:
        Number(bookingData?.promotionDetails?.charges?.original_totalPrice) -
        Number(bookingData?.promotionDetails?.charges?.totalPrice),
      enabled: !!(
        bookingData?.promotionDetails?.promotionId &&
        bookingData?.bookingDetails?.promotionId &&
        bookingData?.promotionDetails?.charges?.original_totalPrice &&
        bookingData?.promotionDetails?.charges?.totalPrice
      ),
      cellProps: {
        py: 0.2,
        borderBottom: 0,
      },
      labelProps: {
        fontWeight: 500,
        fontSize: 14,
        color: palette.success.main,
      },
      valueProps: {
        color: palette.success.main,
      },
    },
    {
      label: "Base Charges",
      value: bookingData?.billDetails?.totalWithoutDiscount,
      enabled: !!(
        bookingData?.billDetails?.isCouponApplied &&
        bookingData?.billDetails?.couponId &&
        bookingData?.billDetails?.totalWithoutDiscount
      ),
      cellProps: {
        py: 0.2,
        borderBottom: 0,
      },
      labelProps: {
        fontWeight: 500,
        fontSize: 14,
        color: palette.error.main,
      },
      valueProps: {
        textDecoration: "line-through",
        color: palette.error.main,
      },
    },
    {
      label: "Coupon Applied",
      value: Number(bookingData?.billDetails?.discountAmount),
      enabled: !!(
        bookingData?.billDetails?.isCouponApplied &&
        bookingData?.billDetails?.couponId &&
        bookingData?.billDetails?.discountAmount
      ),
      cellProps: {
        py: 0.2,
        borderBottom: 0,
      },
      labelProps: {
        fontWeight: 500,
        fontSize: 14,
        color: palette.success.main,
      },
      valueProps: {
        color: palette.success.main,
      },
    },
    {
      label: "Total Charges",
      value: bookingData?.billDetails?.totalCharges,
      enabled: true,
      cellProps: {
        py: 0.2,
        borderBottom: 0,
      },
      labelProps: {
        fontWeight: 600,
      },
      valueProps: {
        fontWeight: 600,
        fontSize: 20,
      },
    },
  ];

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <CardContent>
          <Typography mb={3}>To pay</Typography>
          <Box>
            <TableContainer>
              <Table>
                <TableBody>
                  {chargeDetails.map((item, index, rows) => {
                    const {
                      cellProps = {},
                      labelProps = {},
                      valueProps = {},
                    } = item;
                    return (
                      <Fragment key={index}>
                        {item?.enabled ? (
                          <TableRow
                            key={index}
                            sx={{
                              ".MuiTableCell-root": {
                                borderBottom:
                                  index + 1 === rows?.length ? 0 : "",
                                px: 0,
                              },
                            }}
                          >
                            <TableCell sx={{ ...cellProps }}>
                              <Typography sx={{ ...labelProps }}>
                                {item.label}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className="fm-poppins"
                              sx={{ ...cellProps }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  textAlign: "right",
                                  ...valueProps,
                                }}
                              >
                                {item.value
                                  ? formatPriceWithDelimiter(item.value)
                                  : 0}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            {!bookingData?.promotionDetails?.promotionId ? (
              <Box pt={3}>
                <ApplyCouponForm />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box fontSize={14}>
        <Box pt={3}>
          <FormControlLabel
            onChange={(_, checked) => {
              setErrors((prev) => ({
                ...prev,
                agreeTerms: "",
              }));
              setState((prev) => ({
                ...prev,
                agreeTerms: checked,
              }));
            }}
            control={<Checkbox checked={state?.agreeTerms} />}
            label={
              <Typography fontSize="inherit">
                I agree with the &nbsp;
                <Link
                  to={RouteConstant.TERMS_CONDITIONS}
                  target="_blank"
                  className="fc-primary"
                >
                  terms and conditions
                </Link>{" "}
                &{" "}
                <Link
                  to={RouteConstant.PRIVACY_POLICY}
                  target="_blank"
                  className="fc-primary"
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
          {errors?.agreeTerms ? (
            <FormHelperText sx={{ color: palette.error.main }}>
              {errors.agreeTerms}
            </FormHelperText>
          ) : (
            <></>
          )}
        </Box>

        <Box pt={3} className="p-center">
          <Button
            variant="contained"
            size="large"
            onClick={togglePaymentOption}
            id="last-step-for-payment"
          >
            Pay now
          </Button>
          <CustomJoyRide
            steps={steps}
            stepIndex={stepIndex}
            run={isBookingDataAvailable}
          />
        </Box>
      </Box>

      <Dialog
        open={openPaymentOption}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            ">.MuiDialogContent-root": {
              msOverflowStyle: "none" /* Internet Explorer 10+ */,
              scrollbarWidth: "none" /* Firefox */,
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
          }}
        >
          <PaymentMethod
            handleMakePaymentRequest={handleMakePaymentRequest}
            togglePaymentOption={togglePaymentOption}
            paymentOptions={paymentOptionsList.filter(
              (item) => item.paymentBy !== "wallet"
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CheckoutLayout;
