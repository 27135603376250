import CallIcon from "@mui/icons-material/Call";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import ImportExport from "@mui/icons-material/ImportExport";
import Language from "@mui/icons-material/Language";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import { alpha, lighten, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import InfoCard from "component/_common/cards/infoCards";
import { formatPriceWithDelimiter } from "functions/helper";
import React, { useState } from "react";
import { PackageBundleDetailsProps } from "_interfaces/phoneBooking";

interface Props {
  data?: PackageBundleDetailsProps;
  handleClick?: () => void;
  previewOnly?: boolean;
  showValidityAsChip?: boolean;
}

const PlanCard: React.FC<Props> = ({
  data,
  handleClick,
  previewOnly,
  showValidityAsChip,
}) => {
  const { palette } = useTheme();

  const [hovered, setHovered] = useState<boolean>(false);

  const planColor =
    data?.category === "bronze"
      ? "#CD7F32"
      : data?.category === "gold"
      ? "#FFD700"
      : data?.category === "vip"
      ? "#E005B9"
      : data?.category === "silver"
      ? "#71706E"
      : "";
  return (
    <>
      <Card
        sx={{
          backgroundColor:
            hovered && !previewOnly
              ? lighten(planColor || palette.primary.main, 0.95)
              : "#FFFFFF",
          transition: "all .4s ease",
          // boxShadow: "none",
          border: previewOnly ? "" : "1px solid rgba(0,0,0,0.1)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: 4,
          boxShadow:
            hovered && !previewOnly
              ? "-4px 8px 8px 0px #00000015"
              : !previewOnly
              ? "-2px 2px 6px 0px #00000015"
              : "none",
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        className="fm-poppins"
      >
        <CardContent
          sx={{
            padding: previewOnly ? "0 !important" : "16px",
          }}
        >
          <Box pb={3}>
            {data?.packageName ? (
              <Box fontSize={16} fontWeight={500} textTransform="capitalize">
                {data?.packageName}
              </Box>
            ) : (
              <></>
            )}

            <Box
              pt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {[
                {
                  condition:
                    data?.category &&
                    data?.category?.toLowerCase() !== "normal",
                  label:
                    data?.category?.toLowerCase() === "vip"
                      ? "Premium"
                      : data?.category,
                  backgroundColor: lighten(
                    planColor || palette.secondary.main,
                    0.8
                  ),
                  color: planColor || palette.secondary.main,
                },
                {
                  condition:
                    data?.packageFor &&
                    data?.packageFor?.toLowerCase() !== "all",
                  label: data?.packageFor,
                  backgroundColor: alpha(palette.secondary.main, 0.05),
                  color: palette.secondary.main,
                },
                {
                  condition: showValidityAsChip,
                  label: data?.isValidityUnlimited
                    ? "Non-Expiry"
                    : `${data?.validity} ${
                        data?.validity === 1 ? "Day" : "Days"
                      }`,
                  backgroundColor: alpha(palette.secondary.main, 0.05),
                  color: palette.secondary.main,
                },
              ]
                .filter((chip) => chip.condition)
                .map((chip, index) => (
                  <Chip
                    key={index}
                    label={chip.label}
                    sx={{
                      minWidth: 80,
                      backgroundColor: chip.backgroundColor,
                      color: chip.color,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      borderRadius: "4px",
                    }}
                  />
                ))}
            </Box>
          </Box>

          <Box>
            <InfoCard
              Icon={ImportExport}
              heading={
                data?.dataVolume
                  ? data?.dataVolume?.isUnlimited
                    ? "Non-Expiry"
                    : data?.dataVolume?.quantity && data?.dataVolume?.dataUnit
                    ? `${data?.dataVolume?.quantity} ${data?.dataVolume?.dataUnit}`
                    : "0"
                  : "0"
              }
              desc="DATA"
            />

            {/* <InfoCard
              Icon={CallIcon}
              heading={data?.allNetMinutes || "0"}
              desc="All Net Voice"
            /> */}

            <InfoCard
              Icon={CallIcon}
              multipleHeadingDesc={[
                {
                  heading: data?.onNetMinutes || "0",
                  desc: "On Net Voice",
                },
                {
                  heading: data?.offNetMinutes || "0",
                  desc: "All Net Voice",
                },
              ]}
            />

            <InfoCard
              Icon={Language}
              heading={data?.internationalCallMinutes || "0"}
              desc="International Call Voice"
            />

            <InfoCard
              Icon={DraftsOutlinedIcon}
              heading={data?.allNetSMS || "0"}
              desc="All Net SMS"
            />

            <InfoCard
              Icon={DraftsOutlinedIcon}
              multipleHeadingDesc={[
                {
                  heading: data?.onNetSMS || "0",
                  desc: "On Net SMS",
                },
                {
                  heading: data?.offNetSMS || "0",
                  desc: "Off Net SMS",
                },
              ]}
            />
          </Box>

          {data?.dataPerDay?.isUnlimited ||
          (data?.dataPerDay?.quantity && data?.dataPerDay?.dataUnit) ? (
            <Typography fontSize={14}>
              Daily Limit:{" "}
              {data?.dataPerDay?.isUnlimited
                ? "Non-Expiry"
                : data?.dataPerDay?.quantity && data?.dataPerDay?.dataUnit
                ? `${data?.dataPerDay?.quantity} ${data?.dataPerDay?.dataUnit}`
                : "0"}
            </Typography>
          ) : (
            <></>
          )}
          <Typography fontSize={14}>
            Validity:{" "}
            {data?.isValidityUnlimited
              ? "Non-Expiry"
              : `${data?.validity} ${data?.validity === 1 ? "Day" : "Days"} `}
          </Typography>
          <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
            {data?.retailPrice ? (
              <>UGX {formatPriceWithDelimiter(data.retailPrice)}</>
            ) : (
              <></>
            )}
          </Typography>
        </CardContent>
        {handleClick ? (
          <CardActions
            sx={{ justifyContent: "center", marginTop: "auto" }}
            className="pb-4"
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: hovered ? "#000" : "",
                "&:hover": {
                  backgroundColor: hovered ? "#000" : "",
                },
              }}
              onClick={handleClick}
            >
              Continue
            </Button>
          </CardActions>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default PlanCard;
