import { PaymentByProps } from "_interfaces/_common/payment";

interface Props {
  agreeTerms?: boolean;
  paymentBy?: PaymentByProps | "";
}

const defaultData: Props = {
  agreeTerms: false,
  paymentBy: "",
};

export class CheckoutModel {
  agreeTerms?: boolean;
  paymentBy?: PaymentByProps | "";

  constructor(data: Props = defaultData) {
    this.agreeTerms = data.agreeTerms || false;
    this.paymentBy = data.paymentBy || "";
  }
}
